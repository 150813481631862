@import "breakpoints";
// Custom devices
@mixin rwd($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}

@mixin sm() {
  @media (max-width: $grid-breakpoint-sm-max) {
    @content;
  }
}

@mixin md() {
  @media (max-width: $grid-breakpoint-md-max) {
    @content;
  }
}

@mixin lg() {
  @media (max-width: $grid-breakpoint-lg-max) {
    @content;
  }
}