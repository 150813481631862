@import 'src/assets/scss/partials/variables';
@import "src/styles/_mixins";

.mb-header-container {
  position: absolute;
  top: 0;
  color: #ffde97;
  background-color: white;
  width: 100%;
  height: 7rem;
  display: flex;
  border-bottom: #ff7d00 solid 7px;
  justify-content: center;

  @include md {
    height: 57px;
  }
}

.mb-logo-wrapper {
  width: 63rem;
  display: flex;
  align-items: center;
}

.mb-logo-container {
  cursor: pointer;
  background-image: url("../assets/images/miltenyi-biotec-logo.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 98.66px;
  height: 74px;

  @include md {
    width: 10rem;
    height: 2rem;
    background-size: 10rem 14rem;
    background-position: 0rem -9rem;
    background-repeat: no-repeat;
  }
}

